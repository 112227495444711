import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
  OnInit,
} from '@angular/core'

@Component({
  selector:
    'rmd-initials-gravatar, [rmd-initials-gravatar], [rmdInitialsGravatar]',
  templateUrl: 'rmd-initials-gravatar.component.html',
  exportAs: 'rmdInitialsGravatar',
  styleUrls: ['rmd-initials-gravatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RmdInitialsGravatarComponent implements OnInit {
  @Input() firstName: string
  @Input() lastName: string

  public initials: string

  @HostBinding('class') private get class() {
    return 'rmd-initials-gravatar'
  }

  ngOnInit() {
    this.initials = `${this.firstName.slice(0, 1)}${this.lastName.slice(
      0,
      1
    )}`.toUpperCase()
  }
}
