import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RmdInitialsGravatarComponent } from './rmd-initials-gravatar.component'

@NgModule({
  declarations: [RmdInitialsGravatarComponent],
  imports: [CommonModule],
  exports: [RmdInitialsGravatarComponent],
})
export class RmdInitialsGravatarModule {}
