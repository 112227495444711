import { Injectable } from '@angular/core'
import { HttpClient, HttpBackend } from '@angular/common/http'

import { environment as ENV } from '../../../environments/environment'

import { LogInResponse } from '../models/customer.models'
import { AccessToken } from '@boosterfuels/types/types-ts'
import {
  CheckSignUpStatus,
  CheckSignUpStatusResponse,
  ResetPasswordData,
  ResetPasswordResponse,
  SignUpData,
  SignUpResponse,
} from '../models/fleet-customer.models'

const BASE_URL = `${ENV.BASE_API_URL}/v2/common/Customer`

@Injectable()
export class CustomerApi {
  private httpNoInterceptors: HttpClient

  constructor(handler: HttpBackend) {
    // if for some reason you want to avoid interceptors
    // you can use HttpBackend service instead of HttpClient
    // example: trying to ping a different endpoint than armada api
    this.httpNoInterceptors = new HttpClient(handler)
  }

  login(data: { email: string; password: string }) {
    const url = `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetCustomer/login`
    return this.httpNoInterceptors.post<LogInResponse>(url, data)
  }

  logout(id: string) {
    const url = BASE_URL + '/logout'
    const data = { accessToken: { id } }
    return this.httpNoInterceptors.post(url, data)
  }

  checkSignUpStatus({ email, token }: CheckSignUpStatus) {
    const data: Partial<CheckSignUpStatus> = {}
    if (email) {
      data.email = email
    } else {
      data.token = token
    }
    return this.httpNoInterceptors.post<CheckSignUpStatusResponse>(
      `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetCustomer/checkSignUpStatus`,
      data
    )
  }

  signUp(data: SignUpData) {
    return this.httpNoInterceptors.post<SignUpResponse>(
      `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetCustomer/signUp`,
      data
    )
  }

  resendInviteEmail(email: string) {
    return this.httpNoInterceptors.post(
      `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetCustomer/resendInviteEmail`,
      { email }
    )
  }

  sendPasswordResetEmail(email: string) {
    return this.httpNoInterceptors.post<AccessToken>(
      `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetCustomer/sendPasswordResetLink`,
      { email }
    )
  }

  resetPassword(data: ResetPasswordData) {
    return this.httpNoInterceptors.post<ResetPasswordResponse>(
      `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetCustomer/resetPassword`,
      data
    )
  }
}
